import { Fragment, useEffect, useState } from "react";
import Style from "../scss/ControlInput.module.scss";
import { Icon } from "@iconify/react";
import Picture from "./PictureComponent";
import { url_image} from '../api/ProyectoApi';

export function ContolInputComponent(props) {
    const {icon, name, styleGroup, size, type='checkbox',onChangeValue,verifyCheckStatus,styleTexto,controlSpecial} = props;
    const [activeLabels, setActiveLabels] = useState([]);



    const handleInputChange = (event) => {
        onChangeValue(event);
        
    };
    



    return (
        <Fragment>
            {props.options?.map((option, index) => ( 
                <label key={index} className={`${index === props.options.length - 1 ? Style.last_child : ''} me-2 ${styleGroup} ${Style.Control} ${controlSpecial && Style.movilSpeciales}  ${verifyCheckStatus?.(name,option.value) ? Style.active  : ""}`}
                  >
                    
                    {icon ? 
                        <span className={Style.wpIcon}>
                            <Picture src={url_image+option.icon} alt="Prodesa"  styleGroup={ `${Style.icon} w-auto`}/>
                        </span>
                    : null}                    
                    <span className={`WorkSans  ${styleTexto} ${Style.title} ${icon ? 'text-start' : 'text-center'} ${size === 'regular' ? Style.regular : ''}`}>{option.label}</span>
                    <input  onChange={handleInputChange} type={type} name={name} value={option.value}  checked={verifyCheckStatus?.(name,option.value)} className={Style.input}/>
                    <span className={Style.checkmark}></span>
                </label>
            ))}
        </Fragment>
    );
}