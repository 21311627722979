import { useEffect, useMemo, useState } from 'react';

export const useForm = (initialForm = {}) => {

    const [formState, setFormState] = useState(initialForm);
    const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    const passwordRegex = /^([\w.%+-]){6,}$/;
    const phoneRegex = /^[0-9]{10}$/;
    const documentRegex= /^[0-9]+$/;
    const nameRegex = /^[A-Za-zÁÉÍÓÚÑáéíóúñ ]+$/;


    useEffect(() => {
        setFormState(initialForm);
    }, [])



    const onInputChange = ({ target }) => {
        const { name, value } = target;
         setFormState({
            ...formState,
            [name]: value
        });
    }


    return {
        ...formState,
        formState,
        onInputChange,
        emailRegex,
        passwordRegex,
        phoneRegex,
        nameRegex,
        documentRegex,
        setFormState
    }
}