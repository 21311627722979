import React from 'react';
import styles from '../../scss/Range.module.scss';
import PropTypes from 'prop-types';
import formatNumberMilesPount from './../../utils/formatNumberMilesPount';

/**
 *
 *  @param {{minRange:number, maxRange:number, valueMin:[number, ()=>void], valueMax:[number, ()=>void],  TextSymbol:({value}:{value:string})=>React.ReactNode|undefined}}
 * @returns {React.JSX.Element}
 */
const RangePoints = ({
  valueMin,
  valueMax,
  minRange,
  maxRange,
  TextSymbol,
  styleGroup
}) => {
  let [valueMaxRange, changeMax] = valueMax;
  let [valueMinRange, changeMin] = valueMin;
  valueMaxRange = +valueMaxRange;
  valueMinRange = +valueMinRange;
  minRange = +minRange;
  maxRange = +maxRange;

  const percentageMin =
    ((valueMinRange - minRange) / (maxRange - minRange)) * 100;
  const percentageMax =
    ((valueMaxRange - minRange) / (maxRange - minRange)) * 100;
  return (
    <div className={styles.Range}>
      <div className={styles['multi-range']}>
        <input
          type='range'
          min={minRange}
          value={valueMinRange}
          max={maxRange}
          name='min'
          onChange={changeMin}
        />
        <input
          type='range'
          max={maxRange}
          min={minRange}
          value={valueMaxRange}
          name='max'
          onChange={changeMax}
        />
        <div
          className={styles.thumb}
          style={{
            background: `linear-gradient(to right, #FFD2B2 ${percentageMin}%, 0, #f47921 ${
              percentageMax || 100
            }%, #FFD2B2 0)`
          }}
        ></div>
      </div>
      <div className={styles.rangeText}>
        <div className= {`${styles.min}  ${styleGroup}`}>
          <p>Desde</p>
          <b>
            <TextSymbol value={formatNumberMilesPount(valueMinRange)} />
          </b>
        </div>
        <div className={`${styles.max} ${styleGroup}`}>
          <p >Hasta</p>
          <b>
            <TextSymbol value={formatNumberMilesPount(valueMaxRange)} />
          </b>
        </div>
      </div>
    </div>
  );
};

RangePoints.propTypes = {
  minRange: PropTypes.number.isRequired,
  maxRange: PropTypes.number.isRequired
};
export default RangePoints;
