import React, { Fragment, useRef, useState } from "react";
import style from '../scss/Tooltip.module.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const TooltipComponent = ({ text, text2, children, left, tooltipIcon, styleGroup, html, tootipGeneral }) => {
    const [inputValue, setInputValue] = useState('');
    const [leftPosition, setLeftPosition] = useState(0);
    const inputStyle = {
        left: `${left}%`,
    };

    return (
        <Fragment>
            {!tooltipIcon && !tootipGeneral ? <div className={`${style.tooltip_container} `}>

                <div className={`${style.tooltip_text} text-center ${left > "90" && style.marginLe} ${styleGroup}`} style={inputStyle}>
                    <div className={`${style.tooltip_arrow}`}></div>
                    {html ? (
                        <span dangerouslySetInnerHTML={{ __html: html }} />
                    ) : (
                        text
                    )}
                    <div><b >{text2}</b></div>
                </div>
                <div className={`${style.tooltip_content} `}>{children}</div>
            </div> : null}

            {/*el Tooltip para los iconos */}
            {tooltipIcon ? <div className={`${style.tooltip_container} `}>
                {children}
                <span className={`${style.tooltip_text_icon} ${styleGroup}`}>{text}</span>
            </div> : null}

            {/* tooltip general*/}
            {tootipGeneral ? <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        {text}
                    </Tooltip>
                }
            >
                <div className="d-inline-flex">
                    {children}
                </div>
            </OverlayTrigger> : null}
        </Fragment>
    );
}
export default TooltipComponent;