import React from "react";
import { isMobileOnly, isDesktop, isTablet } from "react-device-detect";
import { TitleH1Component } from "@components/TitleH1Component";
import { TitleH2Component } from "@components/TitleH2Component";
import { BtnComponent } from "@ui/general/BtnComponent";
import Picture from "@ui/general/Picture";
import styleBanner from "@scss/banner.module.scss";
import styleText from "@scss/text.module.scss";

function Banner(props) {
  const {
    pictureSrc,
    pictureSrcMobile,
    pictureSrcTablet,
    alt,
    titleHead,
    titleHeadStyle,
    buttonlink,
    textbutton,
    containerButtonStyle,
    secondarySlider,
    styleGroup,
    contentText,
    styleGroupParagraph,
    containerTextStyle,
    smallBanner,
    styleGroupButton,
    heightBanner,
    ventadanueva,
    pictureStyle,
    sombra,
    lazy,
  } = props;

  return (
    <div
      className={`${styleBanner.banner} ${sombra ? styleBanner.sombra : ""} ${
        heightBanner && heightBanner
      }`}
    >
      {isMobileOnly && pictureSrcMobile && (
        <Picture
          src={pictureSrcMobile}
          alt={alt}
          styleGroup={pictureStyle}
          width="512"
          height="595"
          lazy={lazy}
        />
      )}
      {isTablet && pictureSrcTablet && (
        <Picture
          src={pictureSrcTablet}
          alt={alt}
          styleGroup={pictureStyle}
          width="512"
          height="595"
          lazy={lazy}
        />
      )}
      {isDesktop && pictureSrc && (
        <Picture
          src={pictureSrc}
          alt={alt}
          styleGroup={pictureStyle}
          width="1275"
          height="395"
          lazy={lazy}
        />
      )}
      <div className={styleBanner.wp}>
        <div className={`container ${styleBanner.container}`}>
          {titleHead && (
            <div className="row">
              <div className={`${styleGroup}`}>
                {secondarySlider ? (
                  <TitleH2Component
                    styleGroup={
                      smallBanner ? `${titleHeadStyle}` : `${titleHeadStyle}`
                    }
                  >
                    {titleHead}
                  </TitleH2Component>
                ) : (
                  <TitleH1Component
                    styleGroup={`${titleHeadStyle} ${styleText.h2} ${styleBanner.h2}`}
                  >
                    {titleHead}
                  </TitleH1Component>
                )}
              </div>
              {contentText && (
                <div
                  className={`${styleGroupParagraph} ${styleText.textBanner}`}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: contentText }}
                    className={`white-color ${styleText.textBanner} ${containerTextStyle} ${styleText.paragraph}`}
                  />
                </div>
              )}
              {buttonlink && (
                <div className={`position-relative ${containerButtonStyle}`}>
                  {ventadanueva ? (
                    <BtnComponent
                      clickeable={"1"}
                      target={"_blank"}
                      to={buttonlink}
                      styleGroup={styleGroupButton}
                    >
                      {textbutton}
                    </BtnComponent>
                  ) : (
                    <BtnComponent to={buttonlink} styleGroup={styleGroupButton}>
                      {textbutton}
                    </BtnComponent>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {buttonlink && !ventadanueva && (
        <a
          href={buttonlink}
          className={styleBanner.fullLink}
          aria-label="Banner item link"
          title="Banner link"
        />
      )}
    </div>
  );
}

export default Banner;
