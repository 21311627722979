import { Link } from "react-router-dom";
import style from "../../scss/BuscarBtn.module.scss";
import { useApi } from "../../context/ApiContext";
import { Fragment, useState, useEffect } from "react";
/**
 *
 * @param {{disabled:boolean, search:()=>void}}
 * @returns
 */
const BuscarBtn = ({ disabled, search }) => {
  const { getTextoByKey } = useApi();
  const { titulo } = getTextoByKey("texto-boton-buscador");

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsHighlighted((prevIsHighlighted) => !prevIsHighlighted);
      setTimeout(() => {
        setIsHighlighted(false);
      }, 7000);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowTooltip(false);
    }, 5000);
  },[]);

  return (
    <Fragment>
      {/*<button
        id='btn-buscar '
        disabled={disabled}
        className={`${style.btnBuscador}  WorkSans fw400 `}
        onClick={search}
      >
        <span className={`WorkSans ${style.tooltip}`}>{titulo}</span>
        <span className={`WorkSans`}>Buscar</span>
      </button>*/}
      <button
        id="btn-buscar"
        disabled={disabled}
        className={`${style.btn_brillo} ${style.btn}  WorkSans fw400 `}
        onClick={search}
      >
        {showTooltip && (
          <span className={`WorkSans ${style.tooltip}`}>{titulo}</span>
        )}

        <span
          className={`${style.brillo} ${
            isHighlighted ? style.highlighted : ""
          }`}
        ></span>
        <span className={`WorkSans`}>Buscar</span>
      </button>
    </Fragment>
  );
};

export default BuscarBtn;
