import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { useApi } from "@context/ApiContext";
import { url_image } from "@api/ProyectoApi";
import { TitleH3Component } from "@components/TitleH3Component";
import { ParagraphComponent } from "@components/ParagraphComponent";
import { scrollToTop } from "@components/GlobalFunction";
import { useProyecto } from "@context/ProyectoContext";
import TooltipComponent from "@components/TooltipComponent";
import { TitleH2Component } from "@components/TitleH2Component";
import Picture from "@ui/general/Picture";
import StyleBtn from "@scss/btn.module.scss";
import Style from "@scss/property.module.scss";

export function PropertyComponent({
  proyecto,
  macroPadreNombre,
  macroPadreNombreApp,
  moneda = "COP",
}) {
  const { convertCurrency, getTranslation } = useApi();
  const { getTranslationProy, getIconografias, getPrecio, getArea } =
    useProyecto();
  const [convertedPrice, setConvertedPrice] = useState("");

  const galeria = proyecto?.galeriaProyectos?.find((g) => g.tipo.id == 1);
  const img = galeria?.imagenes?.find(
    (i) => i.visible === true && i.principal === true
  );

  const nombreApp = getTranslationProy(proyecto, "nombreApp");
  const precio = getPrecio(proyecto);
  const nota = getTranslationProy(proyecto, "legal");
  const iconos = getIconografias(proyecto);
  const areaconstruida = getArea(proyecto, 2);
  const ciudad = proyecto?.ciudad?.nombre;
  const citySlug = proyecto?.ciudad?.slug;
  const ubicacion = proyecto?.direccion;
  const vendido = proyecto?.vendido;
  const certificado = proyecto?.icono ? url_image + proyecto.icono.image : "";
  const subsidio = proyecto?.etiquetagris?.nombre;
  const nuevo = proyecto?.etiquetanaranja?.nombre;

  const tipoInmueble =
    getTranslation(proyecto?.tipoinmueble, "nombre", true) != ""
      ? getTranslation(proyecto?.tipoinmueble, "nombre", true)
      : "Apartamento";

  useEffect(() => {
    convertCurrency(precio, moneda).then((result) => {
      setConvertedPrice(result);
    });
  }, [precio, moneda]);

  const baseUrl = proyecto?.esmacro ? "/macroproyecto" : `/proyecto-vivienda/${citySlug}`;
  return (
    <Fragment>
      <div className={Style.property}>
        <Link
          href={`${baseUrl}/${proyecto?.slug}`}
          className={Style.link}
          onClick={scrollToTop}
          aria-label="Link to project"
          title="Link to project"
        />
        <div className={Style.wpImg}>
          <Picture
            src={url_image + img?.image}
            alt={proyecto.alt}
            styleGroup=""
            width={415}
            height={315}
          />
          <div className={`${Style.tags} ${vendido ? Style.bgVendido : ""}`}>
            {vendido && (
              <div className={`${Style.tagVendido}`}>
                <Picture
                  src="/img/vendido.png"
                  alt="Prodesa"
                  width={415}
                  height={315}
                />
              </div>
            )}
            {nuevo && (
              <div className={`bg-ladrillo-oscuro ${Style.tag}`}>
                <ParagraphComponent styleGroup="color-white WorkSans fw500 mb-0">
                  {nuevo}
                </ParagraphComponent>
              </div>
            )}
            {subsidio && (
              <div className={`bg-gris-claro ${Style.tagSubsidio}`}>
                <ParagraphComponent styleGroup="WorkSans fw500 color-gris mb-0">
                  {subsidio}
                </ParagraphComponent>
              </div>
            )}
            {certificado && (
              <div className={Style.tagImg}>
                <Picture
                  src={certificado}
                  alt="Prodesa"
                  styleGroup="w-100"
                  width={300}
                  height={270}
                />
              </div>
            )}
          </div>
        </div>

        <div className="p-2 p-md-3">
          <TitleH3Component styleGroup="WorkSans-medium color-naranja text-uppercase  mb-0">
            {nombreApp}
          </TitleH3Component>
          {macroPadreNombre && (
            <TitleH3Component styleGroup="color-gris  mb-0 fw-normal d-inline-block rounded">
              {macroPadreNombreApp}
            </TitleH3Component>
          )}

          <ParagraphComponent styleGroup="color-gris mb-0  WorkSans fw400 pb-1 fz1">
            {tipoInmueble}s en <b>{ciudad}</b>
          </ParagraphComponent>
          {areaconstruida && (
            <Fragment>
              <ParagraphComponent styleGroup="color-gris mb-0 WorkSans fw400">
                Desde
              </ParagraphComponent>
              <div className="d-flex  align-items-end">
                <TitleH2Component styleGroup="color-gris mb-0 roboto fw500 me-2">
                  {areaconstruida}m<sup>2</sup>
                </TitleH2Component>
                <ParagraphComponent
                  styleGroup={`color-gris mb-0 WorkSans fw400 fz1`}
                >
                  Area construida
                </ParagraphComponent>
              </div>
            </Fragment>
          )}

          <div className="d-flex flex-wrap justify-content-start align-items-center py-2 ">
            {iconos?.map((icon, index) => (
              <div key={index} className={`me-2 ${Style.atributo}`}>
                <TooltipComponent text={icon.icono.nombre} tooltipIcon>
                  <Picture
                    src={url_image + icon.icono?.image}
                    alt="Prodesa"
                    styleGroup="w-100"
                    width={300}
                    height={270}
                  />
                </TooltipComponent>
                <ParagraphComponent styleGroup="color-gris mb-0 ms-1">
                  {icon?.cantidad}
                </ParagraphComponent>
              </div>
            ))}
          </div>

          {!vendido && (
            <>
              <div className="d-flex align-items-start">
                <TitleH3Component styleGroup="color-naranja mb-0 WorkSans-medium pb-1">
                  Desde: {convertedPrice}
                  {nota ? "*" : ""}
                </TitleH3Component>
              </div>
              <ParagraphComponent styleGroup="color-gris mb-0 pb-2 WorkSans fw400 lh ">
                {ciudad}, {ubicacion}
              </ParagraphComponent>
              <ParagraphComponent styleGroup="color-gris mb-0 WorkSans fw400 lh ">
                <small>{nota}</small>
              </ParagraphComponent>
            </>
          )}
        </div>

        <div className={`px-3 ${Style.containerBtn}`}>
          <Link
            href={`${baseUrl}/${citySlug}/${proyecto?.slug}`}
            className={`w-100 ${StyleBtn.btnTarjetaP} ${Style.btnTarjetaP}`}
            onClick={scrollToTop}
            aria-label="Show more"
            title="Show more"
          >
            Ver más
          </Link>
        </div>
      </div>
    </Fragment>
  );
}
