import { Component } from "react";
import { Icon } from "@iconify/react";
import StyleBtn from "@scss/btn.module.scss";
import Style from "@scss/searching.module.scss";
import styleForm from "@scss/form.module.scss";
import RangePoints from "@components/Range/RangePoints";
import BuscarBtn from "@components/BuscarBtn/BuscarBtn";
import formatNumberMilesPount from "@utils/formatNumberMilesPount";

class Search extends Component {
  constructor(props) {
    super(props);
    const ciudadsel = this.props.city
      ? this.props.ciudades.find((cit) => cit.slug == this.props.city)?.nombre
      : "";
    this.state = {
      isHovered: false,
      isHoveredActive: false,
      isActiveMobile: false,
      isFixed: false,
      isTop: false,
      searchFormInitialPosition: {},
      ubicacion: this.props.city,
      ciudadSel: ciudadsel,
      moreFilters: this.props.moreFilters,
      currentPath: this.props.currentPath,
      FiltroFields: this.props.FiltroFields,
      isItem: 0,
      contador: 0,
    };

    this.hoverTimeout = null;
    this.onInputChange = this.props.onInputChange;
    this.searchFilter = this.props.searchFilter;
    this.OnInputChange = this.props.OnInputChange;
    this.styleGroup = this.props.styleGroup;
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    let valor = value;
    this.onInputChange({ target: { value: valor, name: name } });
    if (name == "ubicacion") {
      let ciudadselect =
        this.props.ciudades.find((cit) => cit.slug == value)?.nombre || "";
      this.setState((prevState) => ({
        ...prevState,
        [name]: valor,
        ciudadSel: ciudadselect,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        FiltroFields: {
          ...prevState.FiltroFields,
          [name]: valor,
        },
      }));
    }
  };

  handleScroll = () => {
    const footer = document.querySelector("footer");
    const footerPosition = footer.getBoundingClientRect();
    const searchForm = document.querySelector(`.${Style.searchingForm}`);
    const searchFormPosition = searchForm.getBoundingClientRect();

    let { searchFormInitialPosition } = this.state;

    const scrollPositionY = window.scrollY;

    if (searchFormInitialPosition.top < 0) {
      const searchForm = document.querySelector(`.${Style.searchingForm}`);
      const searchFormPosition = searchForm.getBoundingClientRect();
      this.setState({ searchFormInitialPosition: searchFormPosition });
      searchFormInitialPosition = searchFormPosition;
    }

    if (scrollPositionY > searchFormInitialPosition.top) {
      this.setState({ isTop: true });
    } else {
      this.setState({ isTop: false });
    }

    if (searchFormPosition.bottom >= footerPosition.top) {
      this.setState({ isFixed: true });
    } else {
      this.setState({ isFixed: false });
    }
  };

  handleActiveMobile = (e) => {
    e.preventDefault();
    if (this.state.isActiveMobile === true) {
      this.setState({ isActiveMobile: false });
    } else {
      this.setState({ isActiveMobile: true });
    }
  };

  handleMouseEnter = () => {
    this.setState({ isHoveredActive: true });
    this.hoverTimeout = setTimeout(() => {
      this.setState({ isHovered: true });
    }, 600);
  };

  handleMouseLeave = () => {
    clearTimeout(this.hoverTimeout);
    this.setState({ isHoveredActive: false });
    this.hoverTimeout = setTimeout(() => {
      this.setState({ isHovered: false });
    }, 400);
  };

  OnClearFilters = () => {
    const prevState = this.state;
    this.setState(() => ({
      ...prevState,
      FiltroFields: {
        ...prevState.FiltroFields,
        precioMax: "",
        precioMin: "",
        desde: "",
        hasta: "",
        habitaciones: 0,
      },
    }));
  };

  handleOpenModal = (e) => {
    e.preventDefault();
    if (this.props.openModal) {
      this.props.openModal();
    }
  };

  search = async (e) => {
    e.preventDefault();
    console.log(this.props.filtrosEnvio, "querryy");
    let city = this.state.ubicacion ? "/" + this.state.ubicacion + "?" : "?";

    window.location.href =
      "/proyecto-vivienda" + city + this.props.filtrosEnvio;
  };

  convertCurrency = (value) => {
    let valor = value;
    if (!valor) return 0;
    const numericValue = typeof valor === "string" ? parseFloat(valor) : valor;
    if (isNaN(numericValue)) {
      return "";
    }
    const formattedValue = numericValue.toLocaleString();
    return formattedValue;
  };

  isitemOpcion = (item) => {
    this.setState({ isItem: item });
  };
  aumentarContador = () => {
    let nuevoContador = parseInt(this.props.FiltroFields.habitaciones) + 1;
    if (nuevoContador < parseInt(this.props.habitas.minimo))
      nuevoContador = parseInt(this.props.habitas.minimo);

    if (nuevoContador <= parseInt(this.props.habitas.maximo)) {
      this.handleInputChange({
        target: { value: nuevoContador, name: "habitaciones" },
      });
    } else {
      this.handleInputChange({
        target: { value: this.props.habitas.maximo, name: "habitaciones" },
      });
    }
  };

  disminuirContador = () => {
    if (parseInt(this.props.FiltroFields.habitaciones) > 0) {
      const nuevoContador = parseInt(this.props.FiltroFields.habitaciones) - 1;
      if (nuevoContador <= this.props.habitas.minimo) {
        this.handleInputChange({
          target: { value: this.props.habitas.minimo, name: "habitaciones" },
        });
      } else {
        this.handleInputChange({
          target: { value: nuevoContador, name: "habitaciones" },
        });
      }
    } else {
      this.handleInputChange({
        target: { value: this.props.habitas.minimo, name: "habitaciones" },
      });
    }
  };
  changePriceMin = ({ target: { value } }) => {
    const parseIntValue = +value;
    const price =
      this.props.FiltroFields.precioMax || this.props.precios.maximo;

    if (parseIntValue < price) {
      this.handleInputChange({ target: { value: value, name: "precioMin" } });
    }
  };
  changePriceMax = ({ target: { value } }) => {
    const parseIntValue = +value;
    const price =
      this.props.FiltroFields.precioMin || this.props.precios.minimo;
    if (parseIntValue > price) {
      this.handleInputChange({ target: { value: value, name: "precioMax" } });
    }
  };
  changeAreaMin = ({ target: { value } }) => {
    const parseIntValue = +value;
    if (parseIntValue <= this.props.areas.maximo) {
      this.handleInputChange({ target: { value: value, name: "desde" } });
    }
  };
  changeAreaMax = ({ target: { value } }) => {
    const parseIntValue = +value;
    if (parseIntValue >= this.props.areas.minimo) {
      this.handleInputChange({ target: { value: value, name: "hasta" } });
    }
  };
  /**
   *
   * @param {{value:string}}
   * @returns
   */
  SymbolPrice = ({ value }) => {
    return <>$ {value}</>;
  };
  SymbolArea = ({ value }) => {
    return (
      <>
        {value} m<sup>2</sup>
      </>
    );
  };

  render() {
    const {
      isHoveredActive,
      isActiveMobile,
      isFixed,
      moreFilters,
      isTop,
      ciudadSel,
      isItem,
      FiltroFields,
    } = this.state;
    const { habitaciones, precioMin, precioMax, desde, hasta } = FiltroFields;
    const area = { min: this.props.areas.minimo, max: this.props.areas.maximo };
    const price = {
      priceMin: this.props.precios.minimo,
      priceMax: this.props.precios.maximo,
    };
    const areaLimit = this.props.areas.maximo;
    const areaLimitMin = this.props.areas.minimo;
    const priceLimit = this.props.precios.maximo;
    const priceLimitMin = this.props.precios.minimo;
    const { SymbolArea, SymbolPrice } = this;
    const { ciudades } = this.props;
    return (
      <section className={`d-flex justify-content-center ${Style.Search}`}>
        <form
          className={`${styleForm.form} 
                    ${Style.searchingForm} 
                    ${this.styleGroup} 
                    ${isItem != 0 ? Style.active : ""} 
                    ${isActiveMobile ? Style.activeMobile : ""} 
                    ${isFixed ? Style.positionSearch : ""} 
                    ${isTop ? Style.topSearch : ""} 
                    ${isActiveMobile && !isTop ? Style.bottomAction : ""} ${
            Style.searchForm
          }`}
        >
          <div
            className={`${Style.activeLoad} ${
              isHoveredActive ? Style.Show : ""
            }`}
          />
          <div
            className={`text-center d-md-none p-md-2 ${
              isActiveMobile ? Style.paddingMobile : ""
            }`}
          >
            <button
              className={`w-100 py-2 d-md-block ${
                isActiveMobile ? Style.showSearchMObile : ""
              } ${StyleBtn.btn}`}
              onClick={this.handleActiveMobile}
            >
              {isActiveMobile ? (
                <Icon icon="carbon:close-filled" />
              ) : (
                <>
                  <Icon icon="bi:search" className="me-3" />
                  <span className="WorkSans">Buscar proyecto</span>
                </>
              )}
            </button>
          </div>
          <div
            className={`w-100 ms-0 row ${Style.wpSearchMObile} ${
              isActiveMobile ? Style.active : ""
            }`}
          >
            <div
              className={`ms-0 p-2 p-md-0 gap-2 gap-xxl-4 h-fit-content align-content-start w-100 d-flex flex-wrap col-4 col-md-12 row-gap-0 ${
                Style.hfitContent
              } ${
                isItem !== 0
                  ? "mb-md-4 align-items-start justify-content-center"
                  : "align-items-center justify-content-between"
              }`}
            >
              <div className={Style.formGroup}>
                <div
                  className={`${Style.txt} cursorPointer`}
                  onClick={() => this.isitemOpcion(1)}
                >
                  <div className={`d-flex align-items-center ${Style.wpLabel}`}>
                    <div className={Style.icon}>
                      <Icon icon="bi:geo-alt" />
                    </div>
                    <label
                      className={`color-gris  ${
                        this.state.isItem === 1 && "fw700"
                      } ${this.state.isItem === 0 ? "roboto" : ""}`}
                    >
                      Ciudad
                    </label>
                  </div>
                  <div className={Style.optHide}>
                    <span className={`${Style.ciudad_selec} roboto`}>
                      {ciudadSel}
                    </span>
                  </div>
                </div>
              </div>
              <div className={Style.formGroup}>
                <div
                  className={`${Style.txt} cursorPointer`}
                  onClick={() => this.isitemOpcion(2)}
                >
                  <div className={`d-flex align-items-center ${Style.wpLabel}`}>
                    <div className={Style.icon}>
                      <Icon icon="carbon:currency" />
                    </div>
                    <label
                      className={`color-gris  ${
                        this.state.isItem === 2 && "fw700"
                      } ${this.state.isItem === 0 ? "roboto" : ""}`}
                    >
                      Precio
                    </label>
                  </div>
                  {isItem !== 0 && (
                    <div
                      className={
                        "d-none d-md-flex justify-content-between gap-1 "
                      }
                    >
                      <div className="d-flex flex-column align-items-center">
                        <span className={`text-center WorkSans`}>Desde</span>
                        <span className="WorkSans">
                          <SymbolPrice
                            value={formatNumberMilesPount(
                              precioMin || price.priceMin
                            )}
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-end">
                        <span>-</span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <span className="text-center WorkSans">Hasta</span>
                        <span className="WorkSans">
                          <SymbolPrice
                            value={formatNumberMilesPount(
                              precioMax || price.priceMax
                            )}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={Style.formGroup}>
                <div
                  className={`${Style.txt} cursorPointer`}
                  onClick={() => this.isitemOpcion(3)}
                >
                  <div className={`d-flex align-items-center ${Style.wpLabel}`}>
                    <div className={Style.icon}>
                      <img
                        alt="area"
                        src={"/icons/area.svg"}
                        height={28}
                        width={28}
                        className={Style.area}
                      />
                    </div>
                    <label
                      className={`color-gris  ${
                        this.state.isItem === 3 && "fw700"
                      } ${this.state.isItem === 0 ? "roboto" : ""}`}
                    >
                      Área
                    </label>
                  </div>
                  {isItem !== 0 && (
                    <div
                      className={
                        " d-none d-md-flex justify-content-between gap-1 WorkSans"
                      }
                    >
                      <div className="d-flex flex-column align-items-center">
                        <span className={`WorkSans text-center `}>Desde</span>
                        <span className="WorkSans">
                          <SymbolArea
                            value={formatNumberMilesPount(desde || area.min)}
                          />
                        </span>
                      </div>
                      <div className="d-flex align-items-end">
                        <span>-</span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <span className="text-center WorkSans">Hasta</span>
                        <span className="WorkSans">
                          <SymbolArea
                            value={formatNumberMilesPount(hasta || area.max)}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={Style.formGroup}>
                <div className={Style.txt}>
                  <div
                    className={`d-flex flex-col align-items-center ${Style.wpLabel}`}
                    onClick={() => this.isitemOpcion(4)}
                  >
                    <div className={Style.icon}>
                      <Icon icon="ph:bed" />
                    </div>
                    <label
                      className={`color-gris  ${
                        this.state.isItem === 4 && "fw700"
                      } ${this.state.isItem === 0 ? "roboto" : ""}`}
                    >
                      Habitaciones
                    </label>
                  </div>
                  <div
                    className={`d-flex w-100 justify-content-center ${
                      isItem !== 0 ? "d-flex" : "d-none"
                    }`}
                  >
                    <div
                      className={` gap-2 justify-content-center align-items-center ${Style.rangeRoom} ${Style["rangeRoom--inSection"]}`}
                    >
                      <button
                        type="button"
                        onClick={this.disminuirContador}
                        className="WorkSans color-gris"
                      >
                        -
                      </button>
                      <span
                        className={`${Style.numberOfRoom} WorkSans color-gris`}
                      >
                        {habitaciones}
                      </span>
                      <button
                        type="button"
                        onClick={this.aumentarContador}
                        className="WorkSans color-gris"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {moreFilters ? (
                <div
                  className={`${isItem !== 0 && "order-5"} ${Style.optHide}`}
                >
                  <div className={`${Style.formGroup}`}>
                    <div className={`${Style.txt}`}>
                      <button
                        className={Style.btnfilter}
                        onClick={this.handleOpenModal}
                      >
                        <Icon icon="iconoir:filter" color="#f47921" />
                        <span className="WorkSans">Mas Filtros</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={`d-none d-md-flex flex-md-column gap-md-2 ${Style.formGroup}`}
              >
                <BuscarBtn
                  disabled={this.props.errorPrecios}
                  search={this.search}
                />
                {isItem !== 0 && (
                  <p
                    className={`WorkSans ${Style.clearFilters}`}
                    onClick={this.OnClearFilters}
                  >
                    Limpiar Filtros
                  </p>
                )}
              </div>
            </div>
            {isActiveMobile && <hr />}
            <div
              className={`container px-0 d-flex w-full justify-content-center col-10 ${
                Style.containerOptionSection
              } ${
                (isActiveMobile && (isItem === 1 || isItem === 0)) ||
                isItem === 1
                  ? Style.show
                  : "d-none"
              }`}
            >
              <div className="px-md-3 pb-3 w-100 ">
                <div className="row ms-0 pe-0 gap-5 row-gap-3 w-100 justify-content-center">
                  {ciudades.map((item, index) => (
                    <div
                      className="col-4 col-md-3 col-lg-3 col-xxl-2 pe-0"
                      key={index}
                    >
                      <div className={`${Style.radio_group}`}>
                        <span className={Style.wpIcon}>
                          <Icon
                            icon="bi:geo-alt"
                            width={19}
                            height={19}
                            className={Style.icon}
                          />
                        </span>
                        <input
                          type="radio"
                          id={item.slug}
                          value={item.slug}
                          name="ubicacion"
                          onChange={this.handleInputChange}
                        />
                        <label
                          htmlFor={item.slug}
                          className={`fw400 ${Style.location}`}
                        >
                          {item.nombre}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`container px-0 col-10 col-md-10 ${
                Style.containerOptionSection
              } ${isItem === 2 ? Style.show : "d-none"}`}
            >
              <div className={`px-0 px-md-3 pb-3 ${Style.wpMultiRangeRange} `}>
                <RangePoints
                  valueMin={[precioMin || price.priceMin, this.changePriceMin]}
                  valueMax={[precioMax || price.priceMax, this.changePriceMax]}
                  maxRange={priceLimit}
                  minRange={priceLimitMin}
                  TextSymbol={this.SymbolPrice}
                  styleGroup="WorkSans"
                />
              </div>
            </div>

            <div
              className={`container ${Style.containerOptionSection} ${
                isItem === 3 ? Style.show + " col-10" : "d-none"
              }`}
            >
              <RangePoints
                valueMin={[desde || area.min, this.changeAreaMin]}
                valueMax={[hasta || area.max, this.changeAreaMax]}
                maxRange={areaLimit}
                minRange={areaLimitMin}
                TextSymbol={this.SymbolArea}
                styleGroup="WorkSans"
              />
            </div>
            <div
              className={`container ${Style.containerOptionSection} ${
                isItem === 4 ? Style.show + " col-6 col-md-10" : "d-none"
              }`}
            >
              <div className={"d-flex w-100 justify-content-center"}>
                <div
                  className={`d-flex gap-2 justify-content-center align-items-center ${Style.rangeRoom}`}
                >
                  <button
                    type="button"
                    onClick={this.disminuirContador}
                    className="WorkSans color-gris"
                  >
                    -
                  </button>
                  <span className={`${Style.numberOfRoom} WorkSans color-gris`}>
                    {" "}
                    {habitaciones}
                  </span>
                  <button
                    type="button"
                    onClick={this.aumentarContador}
                    className="WorkSans color-gris"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            <div
              className={Style.iconFlecha}
              onClick={() => this.isitemOpcion(0)}
            >
              <Icon icon="mdi:chevron-up" />
            </div>
            {isActiveMobile && (
              <div
                className={`d-flex justify-content-center w-100 ${Style.formGroup}`}
              >
                <BuscarBtn
                  disabled={this.props.errorPrecios}
                  search={this.search}
                />
              </div>
            )}
          </div>
        </form>
      </section>
    );
  }
}

export default Search;
